import './App.css';
import {Routes, Route, Navigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Index from './views/';
//import Signin from './views/auth/signin';
//import Signup from './views/auth/signup';
import Auth from './views/auth/auth';
import StepOne from './views/new-causes/step-1';
import StepTwo from './views/new-causes/step-2';
import StepThree from './views/new-causes/step-3';
import Causes from './views/dashboard/causes';
import Donations from './views/dashboard/donations';
import Account from './views/dashboard/account';
import NewDonation from './views/donations/new-donation';
import DonationThanks from './views/donations/dontions-thanks';

function App() {
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem('user-session');

    if (session) {
      setIsAuthenticated(true);
      navigate('/causes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Index />} />

        {/* 
        <Route
          path="/signin"
          element={<Signin setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route
          path="/signup"
          element={<Signup setIsAuthenticated={setIsAuthenticated} />}
        /> 
        */}

        <Route
          path="/oauth"
          element={<Auth setIsAuthenticated={setIsAuthenticated} />}
        />

        {/* New Cause */}
        {/*         <Route
          path="/new-causes-step-one"
          element={isAuthenticated ? <StepOne /> : <Navigate to="/" />}
        /> */}
        <Route
          path="/new-causes-step-two"
          element={isAuthenticated ? <StepTwo /> : <Navigate to="/" />}
        />
        <Route
          path="/new-causes-step-three"
          element={isAuthenticated ? <StepThree /> : <Navigate to="/" />}
        />

        {/* Dashboard */}
        <Route
          path="/causes"
          element={
            isAuthenticated ? (
              <Causes setIsAuthenticated={setIsAuthenticated} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/donations"
          element={
            isAuthenticated ? (
              <Donations setIsAuthenticated={setIsAuthenticated} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/account"
          element={
            isAuthenticated ? (
              <Account setIsAuthenticated={setIsAuthenticated} />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* Donation to cause */}
        <Route path="/new-donation" element={<NewDonation />} />
        <Route path="/donation-thank" element={<DonationThanks />} />
      </Routes>
    </div>
  );
}

export default App;
