import {formatDateBT, formatCauseName} from '../components/formater';

export async function getLastTransaction() {
  const user = JSON.parse(localStorage.getItem('user-session'));
  try {
    const response = await fetch(
      `${process.env.REACT_APP_PAYUSD_API}/api/transactions`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      },
    );

    if (response.ok) {
      const result = await response.json();

      const formattedData = result.result.map(item => ({
        confirmationStatus: item.confirmationStatus || '',
        signature: item.signature || '',
        amount: item.uiAmountString || '',
        date: formatDateBT(item.blockTime) || '',
        causeName: formatCauseName(item.memo) || '',
      }));
      return formattedData;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'transactions Error');
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(`Error during transactions: ${error.message}`);
      return [];
    } else {
      throw new Error('Unknown error during transactions');
    }
  }
}

export const fetchCauseLastsTransactions = async ({userToken, causeId}) => {
  if (causeId) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PAYUSD_API}/api/transactions/${causeId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        },
      );

      if (response.ok) {
        const result = await response.json();

        /*     const formattedData = result.assets.tokenBalances.map(item => ({
        symbol: item.symbol || '',
        balance: item.balance || '',
      })); */

        return result;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'account balance Error');
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Error during account balance: ${error.message}`);
      } else {
        throw new Error('Unknown error during account balance');
      }
    }
  } else {
    return [];
  }
};
