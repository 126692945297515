import {useSearchParams, useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import {auth} from '../../api/auth';

const Auth = ({setIsAuthenticated}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const getData = async () => {
      const state = searchParams.get('state');
      const code = searchParams.get('code');

      if (code && state) {
        try {
          const response = await auth({state: state, code: code});
          setTimeout(() => {
            localStorage.setItem('user-session', JSON.stringify(response));
            setIsAuthenticated(true);
            navigate('/causes');
          }, 5000);
        } catch (error) {
          console.log(error.message ? error.message : 'X Login Error.');
        }
      } else {
        navigate('/');
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen w-full flex-col justify-center items-center gap-10 xl:px-[400px] 2xl:px-96">
      <div className="flex justify-center font-semibold mb-4 w-full">
        <p>Processing authentication with X...</p>
      </div>
      <div className="flex justify-center items-center w-full">
        <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-gray-400" />
      </div>
    </div>
  );
};

export default Auth;
