import * as React from 'react';
const BackArrow = props => (
  <svg
    width={20}
    height={17}
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      id="Icon"
      d="M18.491 8.5H2.24102M8.49102 1L1.87491 7.61612C1.38675 8.10427 1.38675 8.89573 1.87491 9.38388L8.49102 16"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default BackArrow;
