import * as React from 'react';
const LogOut = props => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g id="Group">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.599854 3C0.599854 2.20435 0.915924 1.44129 1.47853 0.87868C2.04114 0.316071 2.8042 0 3.59985 0H6.59985C6.75898 0 6.9116 0.0632141 7.02412 0.175736C7.13664 0.288258 7.19985 0.44087 7.19985 0.6C7.19985 0.75913 7.13664 0.911742 7.02412 1.02426C6.9116 1.13679 6.75898 1.2 6.59985 1.2H3.59985C3.12246 1.2 2.66463 1.38964 2.32706 1.72721C1.9895 2.06477 1.79985 2.52261 1.79985 3V9C1.79985 9.47739 1.9895 9.93523 2.32706 10.2728C2.66463 10.6104 3.12246 10.8 3.59985 10.8H6.59985C6.75898 10.8 6.9116 10.8632 7.02412 10.9757C7.13664 11.0883 7.19985 11.2409 7.19985 11.4C7.19985 11.5591 7.13664 11.7117 7.02412 11.8243C6.9116 11.9368 6.75898 12 6.59985 12H3.59985C2.8042 12 2.04114 11.6839 1.47853 11.1213C0.915924 10.5587 0.599854 9.79565 0.599854 9V3Z"
        fill="black"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.48176 3.18962C7.53563 3.13207 7.60032 3.08571 7.67213 3.05317C7.74393 3.02064 7.82145 3.00258 7.90024 3.00001C7.97903 2.99745 8.05755 3.01044 8.13132 3.03823C8.20508 3.06603 8.27265 3.10809 8.33016 3.16202L11.2102 5.86202C11.2701 5.91814 11.3179 5.98597 11.3506 6.06131C11.3832 6.13665 11.4001 6.2179 11.4001 6.30002C11.4001 6.38213 11.3832 6.46338 11.3506 6.53872C11.3179 6.61406 11.2701 6.68189 11.2102 6.73801L8.33016 9.43801C8.27264 9.49191 8.20507 9.53395 8.1313 9.56173C8.05754 9.58951 7.97902 9.60249 7.90024 9.59992C7.82146 9.59736 7.74395 9.57931 7.67215 9.54679C7.60035 9.51427 7.53565 9.46793 7.48176 9.41041C7.42786 9.3529 7.38582 9.28533 7.35804 9.21156C7.33026 9.13779 7.31728 9.05928 7.31985 8.9805C7.32241 8.90172 7.34046 8.82421 7.37298 8.75241C7.4055 8.68061 7.45184 8.61591 7.50936 8.56202L9.28236 6.90002H3.59976C3.44063 6.90002 3.28801 6.8368 3.17549 6.72428C3.06297 6.61176 2.99976 6.45915 2.99976 6.30002C2.99976 6.14089 3.06297 5.98827 3.17549 5.87575C3.28801 5.76323 3.44063 5.70002 3.59976 5.70002H9.28236L7.50936 4.03802C7.45181 3.98414 7.40545 3.91945 7.37291 3.84764C7.34038 3.77584 7.32232 3.69832 7.31975 3.61953C7.31719 3.54074 7.33018 3.46222 7.35797 3.38845C7.38577 3.31469 7.42783 3.24712 7.48176 3.18962Z"
        fill="black"
      />
    </g>
  </svg>
);
export default LogOut;
