import {Link} from 'react-router-dom';
import {formatAmount} from '../components/formater';

function CausesDetails({data}) {
  const lastItem = data.length - 1;

  return (
    <div className="w-full">
      {data.length > 0 ? (
        data.map((item, id) => (
          <Link
            key={id}
            to={`https://explorer.solana.com/tx/${item.signature}?cluster=devnet`}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center py-5 px-6 xl:px-10 mb-2 hover:bg-gray-100 ${lastItem === id ? '' : 'border-b border-black'}`}>
            <div className="flex flex-col gap-4 xl:gap-0 xl:flex-row xl:justify-between w-full">
              <div className="flex flex-col justify-between w-full">
                <div className="flex flex-col xl:flex-row justify-between items-start xl:gap-4 w-full xl:w-1/3 mb-2">
                  <div className="flex flex-row justify-between items-center w-full mb-4">
                    <p className="max-w-96 truncate font-semibold text-lg">
                      {item.causeName !== '' ? item.causeName : 'Unknown Cause'}
                    </p>
                    <div className="xl:hidden flex flex-row justify-end items-start gap-2 text-[#0FA3B1] font-semibold">
                      <p className="max-w-96 truncate">
                        {formatAmount(item.amount)}
                      </p>
                      <p className="">PYUSD</p>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    <p className="rounded-full py-px px-2 bg-gray-100 border border-gray-300 text-sm">
                      {item.confirmationStatus}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col xl:flex-row justify-start items-start xl:gap-2 mb-2">
                  <p className="">Transaction: </p>
                  <p className="max-w-full xl:max-w-96 truncate underline">
                    {item.signature}
                  </p>
                </div>
                <div className="flex flex-row justify-start items-center gap-2">
                  <p className="max-w-96 truncate text-gray-600">{item.date}</p>
                </div>
              </div>
              <div className="hidden xl:flex xl:flex-row xl:justify-end xl:items-start gap-2 text-[#0FA3B1] font-semibold">
                <p className="max-w-96 truncate">{formatAmount(item.amount)}</p>
                <p className="">PYUSD</p>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <div className="flex flex-col mb-5 justify-start border-b border-black py-5 w-full">
          {' '}
          <div className="flex flex-row justify-between mb-4">
            <p className="text-xl">There is still no transactions...</p>
          </div>
        </div>
      )}
    </div>
  );
}
export default CausesDetails;
