import {useEffect, useState} from 'react';
import DashboardNavigation from '../../components/dashboardNavigation';
import {fetchAccountBalance} from '../../api/account';
import TransferModal from '../../components/transferModal';

function Account({setIsAuthenticated}) {
  //const lastActivityItem = activities.length - 1;
  const [userData, setUserData] = useState({});
  const [balances, setBalances] = useState({
    nativeBalance: {
      symbol: '',
      balance: '',
    },
    tokenBalances: [],
  });
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('profile');

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const session = localStorage.getItem('user-session');
      const sessionObj = JSON.parse(session);

      setUserData(sessionObj);
      const balanceData = await fetchAccountBalance({
        userToken: sessionObj.token,
      });
      if (balanceData) {
        setBalances(balanceData);
      } else {
        setBalances({
          nativeBalance: {
            symbol: '',
            balance: '',
          },
          tokenBalances: [],
        });
      }
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <div>
      <DashboardNavigation setIsAuthenticated={setIsAuthenticated} />
      <div className="flex flex-col py-6 px-4 xl:px-60 2xl:px-96 justify-between">
        <div className="flex flex-row gap-6 pb-4 px-4 border-b-2 border-black mb-6 font-semibold">
          <button
            className={`pb-2 ${selectedTab === 'profile' ? 'font-bold border-b-2 border-[#0FA3B1]' : ''}`}
            onClick={() => setSelectedTab('profile')}>
            Profile
          </button>
          <button
            className={`pb-2 ${selectedTab === 'balance' ? 'font-bold border-b-2 border-[#0FA3B1]' : ''}`}
            onClick={() => setSelectedTab('balance')}>
            Balance
          </button>
        </div>
        {selectedTab === 'profile' && (
          <div>
            {' '}
            {/* profile */}
            <div className="px-4 xl:px-40">
              <div className="flex flex-col">
                <p className="w-full text-left mb-2">Full name</p>
                <input
                  type="text"
                  disabled
                  value={userData.name}
                  className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 mb-4 xl:w-3/4"></input>
                <p className="w-full text-left mb-2">X User</p>
                <input
                  type="text"
                  disabled
                  value={`@${userData.email}`}
                  className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 mb-4 xl:w-3/4"></input>
                {/*             <div className="flex flex-row items-center gap-6 mt-5">
              <button className="flex flex-row gap-4 py-2 px-8 w-64 bg-black text-white rounded-md">
              <TwitterX width={20} height={20} />
                Continue with Twitter
                </button>
                <button className="text-gray-700 active:text-gray-900">
                Unlink account
                </button>
                </div> */}
              </div>
            </div>
          </div>
        )}
        {selectedTab === 'balance' && (
          <div>
            {/* balance */}
            <div className="px-4 xl:px-40">
              <div className="flex flex-col items-end gap-6 mb-10">
                {loading ? (
                  // Icono animado de espera
                  <div className="flex justify-center w-full">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-gray-400"></div>
                  </div>
                ) : (
                  <div className="flex flex-col items-end gap-6 w-full">
                    {balances.tokenBalances.map((item, index) => (
                      <p
                        key={index}
                        className="text-lg font-semibold text-[#0FA3B1]">
                        {item.balance} {item.symbol}
                      </p>
                    ))}
                    {balances.tokenBalances.length < 1 && (
                      <p className="text-lg font-semibold text-[#0FA3B1]">
                        0 PYUSD
                      </p>
                    )}
                  </div>
                )}
                <TransferModal
                  isHaveFunds={
                    balances.nativeBalance.symbol !== '' &&
                    balances.tokenBalances.length > 0
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Account;
