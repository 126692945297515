import {Link, useLocation} from 'react-router-dom';
import LogoMin from '../puplic/svg/logoMin';
import LogOut from '../puplic/svg/logout';
import Menu from '../puplic/svg/menu';
import {useState} from 'react';

function DashboardNavigation({setIsAuthenticated}) {
  const [menuBar, setMenuBar] = useState(false);
  const location = useLocation();

  const handleLogOut = () => {
    localStorage.removeItem('user-session');
    setIsAuthenticated(false);
  };

  return (
    <div>
      <div className="flex flex-col justify-between w-screen border-b border-black xl:hidden">
        <div className="flex flex-row justify-between">
          <div>
            <LogoMin className="scale-75 ml-4" />
          </div>
          <div className="bg-black flex p-4">
            {menuBar ? (
              <button onClick={() => setMenuBar(!menuBar)}>
                <div className="w-[30px] h-[35px] text-2xl font-extrabold text-white">
                  X
                </div>
              </button>
            ) : (
              <button onClick={() => setMenuBar(!menuBar)}>
                <Menu width={30} height={35} />
              </button>
            )}
          </div>
        </div>
        {menuBar && (
          <div className="flex flex-col border-t border-black">
            <Link
              to="/causes"
              className={`${location.pathname === '/causes' ? 'font-semibold bg-[#0FA3B1] py-4' : 'py-4'}`}>
              Campaigns
            </Link>
            <Link
              to="/donations"
              className={`${location.pathname === '/donations' ? 'font-semibold bg-[#0FA3B1] py-4' : 'py-4'}`}>
              Transactions {/* Donations */}
            </Link>
            <Link
              to="/account"
              className={`${location.pathname === '/account' ? 'font-semibold bg-[#0FA3B1] py-4' : 'py-4'}`}>
              Account
            </Link>
            <button onClick={handleLogOut} className="py-4 mx-10">
              <div className="flex items-center justify-center gap-2 border rounded-lg border-black py-2 px-6">
                <LogOut width={12} height={12} />
                Logout
              </div>
            </button>
          </div>
        )}
      </div>
      <div className="hidden flex-row gap-40 justify-center xl:flex">
        <div className="absolute left-6 top-6">
          <LogoMin className="scale-75" />
        </div>
        <div className="flex flex-row gap-40 w-full pt-10 justify-center border-b border-black pb-8">
          <Link
            to="/causes"
            className={`${location.pathname === '/causes' ? 'font-semibold border-b-2 border-b-[#0FA3B1] pb-4' : ''}`}>
            Campaigns
          </Link>
          <Link
            to="/donations"
            className={`${location.pathname === '/donations' ? 'font-semibold border-b-2 border-b-[#0FA3B1] pb-4' : ''}`}>
            Transactions {/* Donations */}
          </Link>
          <Link
            to="/account"
            className={`${location.pathname === '/account' ? 'font-semibold border-b-2 border-b-[#0FA3B1] pb-4' : ''}`}>
            Account
          </Link>
        </div>
        <button onClick={handleLogOut} className="pt-10 absolute right-6">
          <div className="flex items-center gap-2 border rounded-lg border-gray-400 py-2 px-6">
            <LogOut width={12} height={12} />
            Logout
          </div>
        </button>
      </div>
    </div>
  );
}
export default DashboardNavigation;
