import React, {useState} from 'react';
import LogoMin from '../puplic/svg/logoMin';
import {sendFunds} from '../api/account';

const TransferModal = ({isHaveFunds}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [account, setAccoun] = useState('');
  const [amount, setAmount] = useState('');

  const handleClickOutside = e => {
    if (e.target.id === 'modal-overlay') {
      setIsOpen(false);
    }
  };

  const handleSendFunds = async () => {
    const data = {
      to: account,
      token: 'PYUSD',
      amount: amount,
    };
    const res = await sendFunds(data);
    if (res) {
      setIsOpen(false);
    }
  };

  return (
    <>
      {/* Botón para abrir el modal */}
      {isHaveFunds && (
        <button
          onClick={() => setIsOpen(true)}
          className="py-2 px-8 bg-black text-white rounded-md">
          Send funds
        </button>
      )}

      {/* Modal */}
      {isOpen && (
        <div
          id="modal-overlay"
          onClick={handleClickOutside}
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl text-left font-semibold mb-4">Send Funds</h2>

            <div className="flex justify-center mb-4">
              <LogoMin className="scale-75" />
            </div>

            <p className="text-left mb-2">Account (PYUSD)</p>
            <input
              type="text"
              value={account}
              onChange={e => setAccoun(e.target.value)}
              placeholder="Wallet (e.g. '4Z4WfpM9nt9K5E1QntRY3S...')"
              className="w-full p-2 mb-6 border border-gray-300 rounded"
            />
            <p className="text-left mb-2">Amount</p>
            <input
              type="number"
              value={amount}
              onChange={e => setAmount(e.target.value)}
              placeholder="PYUSD"
              className="w-full p-2 mb-6 border border-gray-300 rounded"
            />

            <button
              onClick={handleSendFunds}
              className="w-full bg-black text-white p-2 rounded">
              Send
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TransferModal;
