import React from 'react';
//import {Link} from 'react-router-dom';
import {xAuth} from '../api/auth';
import FrameDesign1 from '../puplic/svg/frameDesign1';
import FrameDesign2 from '../puplic/svg/frameDesign2';
import BlackLogoNoBackground from '../puplic/svg/blackLogoNoBackground';
import TwitterX from '../puplic/svg/TwitterX';

const Index = () => {
  const handleLogin = async () => {
    try {
      xAuth();
    } catch (error) {
      console.error('Error initiating Twitter login: ', error);
    }
  };
  return (
    <div className="flex h-screen w-full flex-col items-center justify-between">
      <div className="flex flex-col justify-between items-center mt-4 xl:mt-16 gap-10">
        <BlackLogoNoBackground width={126} height={104} />
        <div className="block xl:flex text-2xl font-bold gap-2">
          <p>Sell online in minutes.</p>
          <p className="text-[#0FA3B1]">No bank account needed.</p>
        </div>
        <div className="text-lg font-normal max-w-md xl:max-w-lg p-4 xl:p-px">
          Create your digital store and receive payments instantly. Share
          products anywhere and start selling with just a few clicks.
        </div>

        {/*         <Link
          to="/signin"
          className=" flex items-center flex-row bg-black text-white py-4 px-8 rounded-lg">
          <div className="font-semibold">Sign in</div>
        </Link>
        <Link
          to="/signup"
          className=" flex items-center flex-row bg-black text-white py-4 px-8 rounded-lg">
          <div className="font-semibold">Sign up</div>
        </Link> */}

        <button
          onClick={handleLogin}
          className="flex items-center justify-center flex-row bg-black text-white py-4 px-8 rounded-lg gap-4 w-4/5 xl:w-auto">
          <TwitterX width={20} height={20} />
          <div className="font-semibold">Continue with X</div>
        </button>

        <div className="text-sm text-gray-500 w-4/5">
          By signing up, you agree to our{' '}
          <dev className="underline">(Terms of Service)</dev> and{' '}
          <dev className="underline">(Data Processing Agreement)</dev>
        </div>
      </div>
      <div className="flex w-full flex-row justify-between">
        <div className="scale-50 translate-y-14 -translate-x-28 xl:scale-75 xl:translate-y-7 xl:-translate-x-14">
          <FrameDesign1 width={422} height={227} />
        </div>
        <div className="scale-50 translate-y-14 -translate-x-[19rem] xl:scale-75 xl:translate-y-7 xl:translate-x-12">
          {/* -translate-x-[21.1rem] */}
          <FrameDesign2 width={423} height={227} />
        </div>
      </div>
      <p className="text-xs m-2 text-gray-500">
        © 2024 soltivo. All rights reserved.
      </p>
    </div>
  );
};

export default Index;
