import DashboardNavigation from '../../components/dashboardNavigation';
import CausesDetails from '../../components/causesDetails';
import {useState, useEffect} from 'react';
import {
  getLastTransaction,
  //fetchCauseLastsTransactions,
} from '../../api/donations';

function Donations({setIsAuthenticated}) {
  const [transactions, setDataTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const transactions = await getLastTransaction();

      if (transactions) {
        setDataTransactions(transactions);
      } else {
        setDataTransactions([]);
      }
      setLoading(false);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <DashboardNavigation setIsAuthenticated={setIsAuthenticated} />
      <div className="flex flex-col py-4 xl:py-10 px-4 xl:px-60 w-full 2xl:px-96 justify-between">
        <div className="w-full text-left mb-6 border-b-2 border-black pb-6">
          <p className="text-lg font-bold w-1/3 xl:w-full">
            Wallet transactions
          </p>
        </div>
        {loading ? (
          // Icono animado de espera
          <div className="flex justify-center w-full">
            <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-gray-400"></div>
          </div>
        ) : (
          <div>
            <CausesDetails data={transactions} />
          </div>
        )}
      </div>
    </div>
  );
}
export default Donations;
