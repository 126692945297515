import {createLinkShareX} from '../components/linkGenerator';

export const newCause = async data => {
  try {
    const bearerToken = JSON.parse(localStorage.getItem('user-session'));
    const response = await fetch(
      `${process.env.REACT_APP_PAYUSD_API}/api/fundraising`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken.token}`,
        },
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      const errorData = await response.json();
      console.log(errorData.message || 'new cause Error');
      return false;
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(`Error during new cause: ${error.message}`);
      return false;
    } else {
      console.log('Unknown error during new cause');
      return false;
    }
  }
};

export const fetchCausesList = async () => {
  try {
    const bearerToken = JSON.parse(localStorage.getItem('user-session'));
    const response = await fetch(
      `${process.env.REACT_APP_PAYUSD_API}/api/fundraising`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken.token}`,
        },
      },
    );

    if (response.ok) {
      const result = await response.json();
      const formattedData = result.map(item => ({
        id: item.id,
        name: item.name,
        description: item.description,
        link: item.slug,
        shareLink: createLinkShareX(item.slug),
        funds: item.donations ? item.donations : '0',
        //status: 'Active',
        amount: item.goal,
      }));
      return formattedData;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'cause list Error');
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(`Error during cause list: ${error.message}`);
      return [];
    } else {
      throw new Error('Unknown error during cause list');
    }
  }
};

export const fetchCausesIds = async () => {
  try {
    const bearerToken = JSON.parse(localStorage.getItem('user-session'));
    const response = await fetch(
      `${process.env.REACT_APP_PAYUSD_API}/api/fundraising`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken.token}`,
        },
      },
    );

    if (response.ok) {
      const result = await response.json();
      const formattedData = result.map(item => ({
        id: item.id,
        name: item.name,
      }));
      return formattedData;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || 'causes Error');
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(`Error during causes: ${error.message}`);
      return [];
    } else {
      throw new Error('Unknown error during causes');
    }
  }
};
