import {Link} from 'react-router-dom';
import {createLink} from './linkGenerator';
import {formatAmount} from '../components/formater';
import TwitterX from '../puplic/svg/TwitterX';

function CausesList({data}) {
  const lastItem = data.length - 1;

  const goalPercentage = (funds, goal) => {
    const percentage = (funds / goal) * 100;
    if (percentage > 5) {
      if (percentage > 100) {
        return 100;
      }
      return (funds / goal) * 100;
    } else if (percentage > 0) {
      return 5;
    } else {
      return 0;
    }
  };

  return (
    <div>
      {data.length > 0 ? (
        data.map((item, index) => (
          <div
            className={`flex flex-col mb-5 justify-between ${lastItem === index ? '' : 'border-b border-black'} pb-2`}
            key={index}>
            <div className="flex flex-col justify-between mb-4">
              <div className="flex flex-col xl:flex-row justify-between">
                <Link
                  to={createLink(item.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col gap-4 mb-2">
                  <div className="flex flex-row items-start gap-2">
                    <p className="font-bold truncate max-w-80">{item.name}</p>
                  </div>
                  <div className="flex flex-row gap-2">
                    <p className="text-sx text-left xl:max-w-96 line-clamp-3">
                      {item.description}
                    </p>
                  </div>
                </Link>
                <div className="flex flex-col justify-start mb-2">
                  <div className="flex flex-row justify-between mb-4">
                    <div className="flex flex-col gap-1 justify-between">
                      <p className="text-left font-semibold">Funds:</p>
                      <p className="flex items-center max-w-60 truncate font-light">
                        {formatAmount(item.funds)} PYUSD
                      </p>
                    </div>
                    <div className="flex flex-col gap-1 justify-between">
                      <p className="text-left font-semibold">Goal: </p>
                      <p className="flex items-center max-w-60 truncate font-light">
                        {formatAmount(parseInt(item.amount))} PYUSD
                      </p>
                    </div>
                  </div>
                  <div className="flex h-5 w-full xl:w-80 justify-end">
                    <div className="w-full bg-gray-300 rounded-full h-5">
                      <div
                        className="bg-[#0FA3B1] h-5 rounded-full"
                        style={{
                          width: `${goalPercentage(item.funds, parseInt(item.amount))}%`,
                        }}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <Link
                  to={item.shareLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex xl:w-28 flex-row items-center justify-center gap-2 py-2 px-2 bg-black text-white rounded-md">
                  <TwitterX className="scale-50" />
                  Share
                </Link>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="flex flex-col mb-5 justify-between border-b border-black pb-5">
          {' '}
          <div className="flex flex-row justify-between mb-4">
            <p className="text-xl">There is still no cause...</p>
          </div>
        </div>
      )}
    </div>
  );
}
export default CausesList;
