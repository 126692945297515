export const formatDate = dateString => {
  const date = new Date(dateString);
  const options = {month: 'short', day: 'numeric'};
  const formattedDate = date.toLocaleDateString('en-US', options);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;
  const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  return `${formattedDate} at ${formattedTime}`;
};

export const formatAmount = number => {
  if (!number) {
    return '';
  }
  return new Intl.NumberFormat('en-US').format(number);
};

export const formatDateBT = blockTime => {
  const date = new Date(blockTime * 1000);
  const options = {month: 'short', day: 'numeric', year: 'numeric'};
  const formattedDate = date.toLocaleDateString('en-US', options);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;
  const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}${ampm}`;
  return `${formattedTime} - ${formattedDate}`;
};

export const formatCauseName = name => {
  if (name) {
    const words = name.replaceAll('-', ' ');
    return words;
  }
};

/*   
  // Ejemplo de uso:
  const formattedDate = formatDate("2024-09-13 18:57:51");
  console.log(formattedDate); // Salida: "Sep 13 at 6:57 pm" */
