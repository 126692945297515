import * as React from 'react';
const Menu = props => (
  <svg
    width={24}
    height={19}
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g id="Group 42">
      <path
        id="Vector 1"
        d="M22.2498 2.36957H2.04688"
        stroke="white"
        strokeWidth={3}
        strokeLinecap="round"
      />
      <path
        id="Vector 2"
        d="M21.9529 9.5H1.75"
        stroke="white"
        strokeWidth={3}
        strokeLinecap="round"
      />
      <path
        id="Vector 3"
        d="M21.9529 16.6304H5.6123"
        stroke="white"
        strokeWidth={3}
        strokeLinecap="round"
      />
    </g>
  </svg>
);
export default Menu;
