import {newCause} from '../../api/causes';
import {useNavigate, Link} from 'react-router-dom';
import {useState} from 'react';
import AWS from 'aws-sdk';
import Camera from '../../puplic/svg/Camera';
import LogoMin from '../../puplic/svg/logoMin';
import BackArrow from '../../puplic/svg/backArrow';

function StepTwo() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [goal, setGoal] = useState();
  const [cover, setCover] = useState();
  const [uploading, setUploading] = useState(0);
  const [error, setError] = useState('');

  const handleFileInput = async e => {
    setError('');
    setUploading(0);
    const selectedFile = e.target.files[0];
    const myBucket = new AWS.S3({
      params: {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      },
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const timestamp = new Date().valueOf().toString();

    const params = {
      Body: selectedFile,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: `soltivo-io/${timestamp}-${selectedFile.name}`,
      ContentType: selectedFile.type,
      //ACL: 'public-read',
    };

    const imageLink = `${process.env.REACT_APP_BUCKET_CLOUDFRONT}soltivo-io/${timestamp}-${selectedFile.name}`;
    setCover(imageLink);

    try {
      await myBucket
        .putObject(params)
        .on('httpUploadProgress', evt => {
          setUploading(parseInt((evt.loaded * 100) / evt.total));
        })
        .promise();
    } catch (err) {
      console.error('Error uploading image: ', err);
      setError('Error uploading image.');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setError('');
    if (cover) {
      if (name !== '' && description !== '' && goal > 0) {
        const response = await newCause({
          name: name,
          description: description,
          cover: cover,
          goal: goal,
        });
        if (response) {
          navigate('/new-causes-step-three', {state: response});
        } else {
          setError('Server error.');
        }
      } else {
        setError('You need to fill out the form.');
      }
    } else {
      setError('You need to fill out the form.');
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between border-b border-black w-full py-px px-4">
        <LogoMin className="scale-75" />
        <Link
          to="/causes"
          className="flex py-2 px-4 xl:px-8 bg-[#0FA3B1] border-2 border-black text-black rounded-md font-bold">
          <BackArrow />
        </Link>
      </div>
      <div className="flex flex-col items-start justify-between py-6 px-6 xl:px-40 2xl:px-96">
        <div className="my-6">
          <p className="mb-4 text-left font-bold text-xl">
            Set Up Your Fundraising Page
          </p>
          <p className="text-left">
            Showcase your cause and set donation options
          </p>
        </div>
        <div className="mb-4 w-full xl:w-2/5">
          <p className="text-left w-full">Fundraise Cover Image</p>
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="file-upload"
              className="flex flex-col items-center justify-center w-full h-44 border border-black rounded-md cursor-pointer hover:bg-gray-100">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <Camera width={55} height={55} />
                <p className="mb-2 text-sm text-gray-500">Upload cover</p>
              </div>
              <input
                id="file-upload"
                className="hidden"
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileInput}
              />
            </label>
          </div>
          <p className="text-left">Recommended size: 1200x630px</p>
          {uploading > 0 && (
            <div className="flex flex-row gap-2">
              <p>loading image: </p>
              <p className={`${uploading === 100 && `text-green-600`}`}>
                {uploading}%
              </p>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit} className="w-full">
          <div className="mb-4 w-full xl:w-3/4">
            <p className="text-left w-full">Campaign title</p>
            <input
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Enter name best describe your cause name"
              className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 w-full"></input>
          </div>
          <div className="mb-4 w-full xl:w-3/4">
            <div className="flex flex-row justify-between w-full">
              <p>Description</p>
              <p>max. 500 characters</p>
            </div>
            <textarea
              className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 w-full"
              value={description}
              onChange={e => setDescription(e.target.value)}
              name="description"
              id="description"
              maxLength="500"
              placeholder="Describe your cause and how donations will make a difference "
              rows="7"></textarea>
          </div>
          <div className="flex flex-col xl:flex-row gap-2 xl:gap-0 mb-4">
            <p className="mr-4 flex items-center">Donation goal amount:</p>
            <div className="flex flex-row xl:w-3/4">
              <input
                className="border border-black hover:bg-gray-100 rounded-md px-4 py-2 w-full xl:w-1/2"
                type="number"
                value={goal}
                onChange={e => setGoal(e.target.value)}
                placeholder="Add amount"></input>
              <p className="ml-4 flex items-center font-semibold">USD</p>
            </div>
          </div>
          {/* <div className="flex flex-row mb-4">
          <p className="mr-4 flex items-center">Allow custom amount:</p>
          <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" class="sr-only peer" />
            <div class="w-12 h-6 bg-black rounded-full peer-checked:bg-gray-400"></div>
            <div class="absolute w-5 h-5 bg-white rounded-full left-1 top-0.5 peer-checked:translate-x-full transition-transform"></div>
          </label>
        </div> */}
          <div className="w-full mb-4">
            <button
              type="submit"
              disabled={uploading < 100}
              className="py-2 px-8 bg-black text-white rounded-md disabled:opacity-50">
              Next Step
            </button>
          </div>
          {error && (
            <div className="">
              <p className="font-semibold text-red-500">Error</p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
export default StepTwo;
